<template>
  <div>
    <h4>{{ $t('dashboard.totalProductUsedReport.title') }}</h4>
    <div>
      <v-btn
        :outline="isMonthChart"
        class="ma-2"
        color="blue darken-1"
        dark
        small
        @click="onChangeTypeData(false)"
      >
        {{ $t('dashboard.byDate') }}
      </v-btn>
      <v-btn
        :outline="!isMonthChart"
        class="ma-2"
        outlined
        color="blue darken-1"
        dark
        small
        @click="onChangeTypeData(true)"
      >
        {{ $t('dashboard.byMonth') }}
      </v-btn>
    </div>
    <opinion-chart
      ref="chartProductUsedReport"
      :chart-config="config"/>
    <v-flex
      class="pa-2"
      text-xs-left>
      <v-spacer/>
      <span>{{ $t('dashboard.totalProductUsedReport.totalProductUsed') }}:</span> <span class="font-weight-bold">{{ formatInteger(totalValue) }}</span>
    </v-flex>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import OpinionChart from '@/components/ChartjsComponent'
import functionUtils from 'utils/functionUtils'
export default {
  components: {
    OpinionChart
  },
  data: () => ({
    config: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            // label: '# of Votes',
            data: [],
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true
          }
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [{
            barPercentage: 0.7,
            categoryPercentage: 0.8,
            gridLines: {
              color: 'rgba(0, 0, 0, 0)'
            }
          }],
          yAxes: [{
            offset: true,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                let number = value !== undefined || value !== null ? value !== 0 ? value : '' : ''
                if (String(number).length > 3) {
                  number = number.toString().replace(/[^A-Z0-9]/ig, '')
                  number = Number(number).toFixed(0).replace(/./g, function (c, i, a) {
                    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? '.' + c : c
                  })
                }
                return number
                // return value
              }
            }
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: -4,
            clamp: true,
            formatter: function (value, context) {
              let number = value !== undefined || value !== null ? value !== 0 ? value : '' : ''
              if (String(number).length > 3) {
                number = number.toString().replace(/[^A-Z0-9]/ig, '')
                number = Number(number).toFixed(0).replace(/./g, function (c, i, a) {
                  return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? '.' + c : c
                })
              }
              return number
            }
          }
        }
      }
    },
    totalValue: 0,
    isMonthChart: false
  }),
  computed: {
    ...mapGetters([
      'GET_PRODUCT_USED_REPORT_DATA'
    ])
  },
  watch: {
    GET_PRODUCT_USED_REPORT_DATA: function () {
      let data = this.GET_PRODUCT_USED_REPORT_DATA
      this.config.data.datasets = []
      this.config.data.labels = data.is_month_chart ? data.label : this.formatDateList(data.label, 'YYYY-MM-DD', 'DD/MM')
      let chargePointsData = {
        label: this.$t('dashboard.totalProductUsedReport.labels.customer'),
        data: data.customer,
        backgroundColor: 'rgb(0, 204, 204)',
        borderColor: 'rgba(0, 204, 204, 1)',
        borderWidth: 1
      }
      this.config.data.datasets.push(chargePointsData)
      let usePointsData = {
        label: this.$t('dashboard.totalProductUsedReport.labels.prepaidCard'),
        data: data.user_ghost,
        backgroundColor: 'rgb(127, 0, 255)',
        borderColor: 'rgba(127, 0, 255, 1)',
        borderWidth: 1
      }
      this.config.data.datasets.push(usePointsData)
      this.totalValue = data.customer.reduce((a, b) => a + b, 0) + data.user_ghost.reduce((a, b) => a + b, 0)
      // this.config.data.labels = this.formatDateList(data.label, 'YYYY-MM-DD', 'DD/MM')
      // this.config.data.datasets[0].data = data.value
      // this.totalValue = data.value.reduce((a, b) => a + b, 0)
      this.renderChartData()
    }
  },
  created () {
    // let currentDate = moment().format('YYYY-MM-DD')
    // let filter = {
    //   params: {
    //     current_date: currentDate
    //   }
    // }
    // this.GET_PRODUCT_USED_REPORT(filter)
    // this.renderChartData()
    this.getData()
  },
  methods: {
    formatInteger: function (num) {
      num = functionUtils.formatInteger(num && num.toString())
      return functionUtils.convertFormatNumberToMoney(num)
    },
    renderChartData: function () {
      if (this.$refs.chartProductUsedReport !== undefined) {
        this.$refs.chartProductUsedReport.renderChartChangeData()
      }
    },
    formatDateList: function (dateList, fromFormat, toFormat) {
      let resultList = []
      dateList.forEach(data => {
        resultList.push(moment(data, fromFormat).format(toFormat))
      })
      return resultList
    },
    getData: function () {
      let currentDate = moment().format('YYYY-MM-DD')
      let currentYear = moment().format('YYYY')
      let filter = null
      if (this.isMonthChart) {
        filter = {
          params: {
            current_year: currentYear
          }
        }
      } else {
        filter = {
          params: {
            current_date: currentDate
          }
        }
      }
      this.GET_PRODUCT_USED_REPORT(filter)
      // this.renderChartData()
    },
    onChangeTypeData: function (value) {
      this.isMonthChart = value
      this.getData()
    },
    ...mapActions([
      'GET_PRODUCT_USED_REPORT'
    ])
  }
}
</script>

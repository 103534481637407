<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout wrap>
      <v-flex
        sm12
        xs12
        md12
        lg6>
        <v-card-title class="my-0 py-0 font-weight-medium black--text">{{ $t('dashboard.newCustomerReport.title') }}</v-card-title>
        <v-layout wrap>
          <v-flex
            sm6
            xs12
            md6
            lg6>
            <material-stats-card
              :value="formatInteger(newCustomerActiveData.value)"
              :sub-text="$t(newCustomerActiveData.typeText)"
              :value-size="40"
              color="indigo lighten-1"
              sub-text-color="font-weight-bold black--text"
              icon="mdi-account-multiple-check"
              sub-icon="mdi-format-title"
            />
          </v-flex>
          <v-flex
            sm6
            xs12
            md6
            lg6>
            <material-stats-card
              :value="formatInteger(newPrepaidCardActiveData.value)"
              :sub-text="$t(newPrepaidCardActiveData.typeText)"
              :value-size="40"
              color="green"
              sub-text-color="font-weight-bold black--text"
              icon="mdi-credit-card-check"
              sub-icon="mdi-format-title"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        sm12
        xs12
        md12
        lg6>
        <v-card-title class="my-0 py-0 font-weight-medium black--text">{{ $t('dashboard.rechargeAndUsePoints.title') }}</v-card-title>
        <v-layout wrap>
          <v-flex
            sm6
            xs12
            md6
            lg6>
            <material-stats-card
              :value="formatInteger(numberPointsUsed.value)"
              :sub-text="$t(numberPointsUsed.typeText)"
              :value-size="40"
              color="red"
              value-color="orange--text"
              sub-text-color="font-weight-bold black--text"
              icon="mdi-bitcoin"
              sub-icon="mdi-format-title"
            />
          </v-flex>
          <v-flex
            sm6
            xs12
            md6
            lg6>
            <material-stats-card
              :value="formatInteger(numberPointsRecharge.value)"
              :sub-text="$t(numberPointsRecharge.typeText)"
              :value-size="40"
              color="purple lighten-1"
              value-color="orange--text"
              sub-text-color="font-weight-bold black--text"
              icon="mdi-credit-card-plus"
              sub-icon="mdi-format-title"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        sm12
        xs12
        md12
        lg6>
        <v-card-title class="my-0 py-0 font-weight-medium black--text">{{ $t('dashboard.refundPointsReport.title') }}</v-card-title>
        <v-layout wrap>
          <v-flex
            sm6
            xs12
            md6
            lg6>
            <material-stats-card
              :value="formatInteger(numberPointRefund.value)"
              :sub-text="$t(numberPointRefund.typeText)"
              :value-size="40"
              color="orange"
              value-color="red--text text--lighten-1"
              sub-text-color="font-weight-bold black--text"
              icon="mdi-credit-card-refresh"
              sub-icon="mdi-format-title"
            />
          </v-flex>
          <v-flex
            sm6
            xs12
            md6
            lg6>
            <material-stats-card
              :value="formatInteger(numberUserReturn.value)"
              :sub-text="$t(numberUserReturn.typeText)"
              :value-size="40"
              color="info"
              value-color="red--text text--lighten-1"
              sub-text-color="font-weight-bold black--text"
              icon="mdi-account-convert"
              sub-icon="mdi-format-title"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout wrap>
        <v-flex
          sm12
          xs12
          md12
          lg6>
          <v-card
            class="pa-4">
            <charge-points-report/>
          </v-card>
        </v-flex>
        <v-flex
          sm12
          xs12
          md12
          lg6>
          <v-card
            class="pa-4">
            <use-points-report/>
          </v-card>
        </v-flex>
        <v-flex
          sm12
          xs12
          md12
          lg6>
          <v-card
            class="pa-4">
            <total-product-used-report/>
          </v-card>
        </v-flex>
        <v-flex
          sm12
          xs12
          md12
          lg6>
          <v-card
            class="pa-4">
            <account-created-report/>
          </v-card>
        </v-flex>
      </v-layout>
      <!-- <v-flex
        sm6
        xs12
        md6
        lg6>
        <v-card>
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            />
          </template>
          <v-card-title class="pb-2">{{ $t('dashboard.newCustomerReport.title') }}</v-card-title>
          <v-card-text class="py-2">
            <table class="table table-hover">
              <tbody>
                <tr
                  v-for="(item, index) in newCustomerList"
                  :key="item+index">
                  <th
                    class="text-right"
                    scope="row">{{ formatInteger(item.value) }}</th>
                  <td>{{ $t(getLabelText(item.type)) }}</td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex
        sm6
        xs12
        md6
        lg6>
        <v-card>
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            />
          </template>
          <v-card-title class="pb-2">{{ $t('dashboard.rechargeAndUsePoints.title') }}</v-card-title>
          <v-card-text class="py-2">
            <table class="table table-hover">
              <tbody>
                <tr
                  v-for="(item, index) in rechargeAndUsePointsList"
                  :key="item+index">
                  <th
                    class="text-right"
                    scope="row">{{ formatInteger(item.value) }}</th>
                  <td>{{ $t(getLabelText(item.type)) }}</td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex
        sm6
        xs12
        md6
        lg6>
        <v-card>
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            />
          </template>
          <v-card-title class="pb-2">{{ $t('dashboard.refundPointsReport.title') }}</v-card-title>
          <v-card-text class="py-2">
            <table class="table table-hover">
              <tbody>
                <tr
                  v-for="(item, index) in refundPointsList"
                  :key="item+index">
                  <th
                    class="text-right"
                    scope="row">{{ formatInteger(item.value) }}</th>
                  <td>{{ $t(getLabelText(item.type)) }}</td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
import ChargePointsReport from './ChartReport/ChargePointsReport'
import UsePointsReport from './ChartReport/UsePointsReport'
import AccountCreatedReport from './ChartReport/AccountCreatedReport'
import TotalProductUsedReport from './ChartReport/TotalProductUsedReport'
import { mapActions } from 'vuex'
import moment from 'moment'
import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import reportType from 'enum/reportType'
export default {
  components: {
    ChargePointsReport,
    UsePointsReport,
    AccountCreatedReport,
    TotalProductUsedReport
  },
  data () {
    return {
      reportLabelTextList: stringUtils.reportLabelTextList,
      newCustomerList: [],
      rechargeAndUsePointsList: [],
      refundPointsList: [],
      newCustomerActiveData: {
        value: 0,
        typeText: null
      },
      newPrepaidCardActiveData: {
        value: 0,
        typeText: null
      },
      numberPointsUsed: {
        value: 0,
        typeText: null
      },
      numberPointsRecharge: {
        value: 0,
        typeText: null
      },
      numberPointRefund: {
        value: 0,
        typeText: null
      },
      numberUserReturn: {
        value: 0,
        typeText: null
      }
    }
  },
  created () {
    let toFormat = 'YYYY-MM-DD HH:mm:ss'
    let filter = {
      params: {
        start_date: this.getStartOfDayUTC(moment(), toFormat),
        end_date: this.getEndOfDayUTC(moment(), toFormat)
      }
    }
    this.getNewCustomerCurrentDate(filter)
    this.getRechargeAndUsePointsCurrentDate(filter)
    this.getRefundPointsCurrentDate(filter)
  },
  methods: {
    getStartOfDayUTC: function (dateValue, toFormat) {
      return dateValue.startOf('day').utc().format(toFormat)
    },
    getEndOfDayUTC: function (dateValue, toFormat) {
      return dateValue.endOf('day').utc().format(toFormat)
    },
    formatInteger: function (num) {
      num = functionUtils.formatInteger(num && num.toString())
      return functionUtils.convertFormatNumberToMoney(num)
    },
    complete (index) {
      this.list[index] = !this.list[index]
    },
    getLabelText: function (value) {
      let index = this.reportLabelTextList.findIndex(x => x.value === value)
      let result = index !== -1 ? this.reportLabelTextList[index].text : ''
      return result
    },
    getDataReport: function (dataList, reportType) {
      let index = dataList.findIndex(x => x.type === reportType)
      let result = index !== -1 ? dataList[index] : null
      return result
    },
    getNewCustomerCurrentDate: function (filter) {
      this.GET_NEW_CUSTOMER_CURRENT_DATE(filter).then(
        function (res) {
          let data = res.data
          let newCustomerActiveData = this.getDataReport(data, reportType.NUMBER_USER_ACTIVE)
          this.newCustomerActiveData = {
            value: newCustomerActiveData.value,
            typeText: this.getLabelText(newCustomerActiveData.type)
          }
          let newPrepaidCardActiveData = this.getDataReport(data, reportType.NUMBER_PREPAID_CARD_ACTIVE)
          this.newPrepaidCardActiveData = {
            value: newPrepaidCardActiveData.value,
            typeText: this.getLabelText(newPrepaidCardActiveData.type)
          }
        }.bind(this)
      )
    },
    getRechargeAndUsePointsCurrentDate: function (filter) {
      this.GET_RECHARGE_AND_USE_POINTS_CURRENT_DATE(filter).then(
        function (res) {
          let data = res.data
          let numberPointsUsed = this.getDataReport(data, reportType.NUMBER_POINTS_USED)
          this.numberPointsUsed = {
            value: numberPointsUsed.value,
            typeText: this.getLabelText(numberPointsUsed.type)
          }
          let numberPointsRecharge = this.getDataReport(data, reportType.NUMBER_RECHARGE_POINTS)
          this.numberPointsRecharge = {
            value: numberPointsRecharge.value,
            typeText: this.getLabelText(numberPointsRecharge.type)
          }
        }.bind(this)
      )
    },
    getRefundPointsCurrentDate: function (filter) {
      this.GET_REFUND_POINTS_CURRENT_DATE(filter).then(
        function (res) {
          let data = res.data
          let numberPointRefund = this.getDataReport(data, reportType.NUMBER_REFUND_POINTS)
          this.numberPointRefund = {
            value: numberPointRefund.value,
            typeText: this.getLabelText(numberPointRefund.type)
          }
          let numberUserReturn = this.getDataReport(data, reportType.NUMBER_USER_RETURN)
          this.numberUserReturn = {
            value: numberUserReturn.value,
            typeText: this.getLabelText(numberUserReturn.type)
          }
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_NEW_CUSTOMER_CURRENT_DATE',
      'GET_RECHARGE_AND_USE_POINTS_CURRENT_DATE',
      'GET_REFUND_POINTS_CURRENT_DATE'
    ])
  }
}
</script>
